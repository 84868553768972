import { $, $$ } from '../helpers/query-selector';
import onScroll from '../helpers/on-scroll';
import { hideSubList } from './nav-desktop';

const header = $('.js-header');
const headerHiddenClass = 'l-header--hidden';
const headerHideThreshold = 300;
const navDesktop = $('.js-nav-desktop');
const anchorNav = $('.js-anchor-nav');

function initHeader() {
  let prevScrollY = window.scrollY;

  let headerHideScrollOffset = prevScrollY + 50;
  if (anchorNav)
    anchorNav.dataset.offset = header.offsetHeight + anchorNav.offsetHeight;

  onScroll(() => {
    // Scroll up
    if (window.scrollY < prevScrollY) {
      if (window.scrollY > headerHideThreshold) {
        if (header.dataset.preventScroll != 'true') {
          header.classList.remove(headerHiddenClass);

          // Anchor nav
          if (anchorNav) {
            anchorNav.classList.remove('l-anchor-nav--position-top');
            anchorNav.dataset.offset =
              header.offsetHeight + anchorNav.offsetHeight;
          }

          const navRubrics = $$('.js-nav-rubric', navDesktop);
          for (const rubric of navRubrics) {
            const subListToggle = $('.js-nav-toggle-sublist', rubric);
            const subList = $('.js-nav-sublist', rubric);

            hideSubList(subListToggle, subList);
          }

          headerHideScrollOffset = prevScrollY + 50;
        }
      }
    }
    // Scroll down
    else {
      if (header.dataset.preventScroll != 'true') {
        if (
          window.scrollY > headerHideThreshold &&
          prevScrollY > headerHideScrollOffset
        ) {
          header.classList.add(headerHiddenClass);

          // Anchor nav
          if (anchorNav) {
            anchorNav.classList.add('l-anchor-nav--position-top');
            anchorNav.dataset.offset = anchorNav.offsetHeight;
          }
        }
      }
    }

    prevScrollY = window.scrollY;
  });

  header.addEventListener('focusin', () => {
    if (header.dataset.preventScroll != 'true') {
      header.classList.remove(headerHiddenClass);

      // Anchor nav
      if (anchorNav) {
        anchorNav.classList.remove('l-anchor-nav--position-top');
        anchorNav.dataset.offset = header.offsetHeight + anchorNav.offsetHeight;
      }
    }
  });
}

function init() {
  initHeader();
}

export default init;
