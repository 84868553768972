import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initSetVh from './modules/set-vh';
import initLazyLoad from './modules/lazyload';
import initNavMobile from './modules/nav-mobile';
import initNavDesktop from './modules/nav-desktop';
import initHeader from './modules/header';
import initAnimation from './modules/animation';
import initVideo from './modules/video';

async function init() {
  // Optional polyfills
  // const polyfills = [];
  // if (!('Feature' in window)) polyfills.push(import('feature-polyfill'));
  // await Promise.all(polyfills);

  // Immediate
  initSetVh();
  initLazyLoad();
  initNavDesktop();
  initNavMobile();
  initHeader();
  initAnimation();
  initVideo();

  // Lazyloaded
  // const modules = ['tracking'];

  // for (const module of modules) {
  //   import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  // }

  // Conditionally lazyloaded
  const conditionalModules = {
    '.js-anchor-scroll': 'anchor-scroll',
    '.js-anchor-nav': 'anchor-nav',
    '.js-slider': 'slider',
    '.js-form': 'form',
    '.js-textarea': 'textarea',
    '.js-books': 'books',
    '.js-history': 'history',
    '.js-accordion': 'accordion',
    '.js-more': 'more',
    '.js-iframe': 'iframe',
    '.js-intro': 'intro',
    '.js-cookie-consent': 'cookie-consent'
  };

  for (const [selector, module] of Object.entries(conditionalModules)) {
    if ($(selector)) import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  }
}

init();
